import { useMutation } from '@tanstack/react-query'
import { httpPOST } from 'lib/http'

export interface IPayInvoicesReq {
  invoiceIds: Array<string>
}

export interface IPayInvoicesRes {
  messageTitle: string | null
  messageDescription: string | null
  messageType: string | null
  messageCode: string | null
  data: {
    invoiceIds: Array<string>
    id: string
    paymentRedirectURL: string
  } | null
}

export const payInvoices = async (req: IPayInvoicesReq) => {
  const { data } = await httpPOST({
    url: `invoice/pay`,
    body: req
  })
  return data as IPayInvoicesRes
}

export const usePayInvoices = () => {
  return useMutation({
    mutationFn: payInvoices
  })
}
