import { httpPOST } from 'lib/http'

import type { IInvoiceDetails } from '../myAccount'

export interface IGetInvoiceByRowRequest {
  rowindex: number
  date?: {
    startDate: string
    endDate: string
  }
  type?: Array<number>
  amount?: {
    start: number
    end: number
  }
  statuses: Array<number>
}

export interface IGetInvoiceByRowResponse {
  messageTitle: string | null
  messageDescription: string | null
  messageType: string
  data: IInvoiceDetails
}

export const getInvoiceByRow = async (req: IGetInvoiceByRowRequest, context?: any) => {
  const response = await httpPOST({
    url: `invoice/get_invoice_by_raw`,
    body: req,
    context
  })

  return response?.data as IGetInvoiceByRowResponse
}
