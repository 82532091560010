import { FC } from 'react'
import CN from 'classnames'

export interface AccountDetailsSummaryProps {
  className?: string
  details: Array<{
    title: string
    value: string
  }>
  summaryCardClassName?: string
  summaryCardTitle: string
}

export const AccountDetailsSummaryCard: FC<AccountDetailsSummaryProps> = ({
  className,
  details,
  summaryCardClassName,
  summaryCardTitle
}: AccountDetailsSummaryProps) => {
  return (
    <div className={CN('flex flex-col gap-[20px] p-[16px] border border-N-200 rounded', className)}>
      <span
        className={CN(
          'text-h2-m tracking-[-0.56px] text-B-500 font-700 py-[16px] px-[8px]',
          summaryCardClassName
        )}>
        {summaryCardTitle}
      </span>
      <div className='flex flex-col gap-[8px]'>
        {details.map(({ title, value }) => (
          <div key={title} className='flex w-full gap-[16px] p-[8px]'>
            <span className='text-base text-N-600'>{title}</span>
            <div className='flex justify-center items-center flex-grow'>
              <hr className='flex-grow border-dashed border-N-400 border-t-[2px]' />
            </div>
            <span className='text-base text-N-600'>{value}</span>
          </div>
        ))}
      </div>
    </div>
  )
}

export default AccountDetailsSummaryCard
