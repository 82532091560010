import { useRouter } from 'next/router'
import { FC } from 'react'
import { RangeInput } from '@nzsb/shopnx-ui'
import { changeRouter } from 'lib/utilities'

export interface IAmountPickerProps {
  componentId: string
  label: string
  maxPrice?: number
  minPrice?: number
  setMaxPrice: React.Dispatch<React.SetStateAction<number | undefined>>
  setMinPrice: React.Dispatch<React.SetStateAction<number | undefined>>
}

/* AmountPicker component */
export const AmountPicker: FC<IAmountPickerProps> = ({
  componentId,
  label,
  maxPrice,
  minPrice,
  setMaxPrice,
  setMinPrice
}: IAmountPickerProps) => {
  const router = useRouter()

  const MIN_PRICE = 'minPrice'
  const MAX_PRICE = 'maxPrice'
  return (
    <RangeInput
      minValue={minPrice}
      maxValue={maxPrice}
      componentId={`${componentId}-range-input`}
      onApply={(prices: any) => {
        setMinPrice(prices.minValue)
        setMaxPrice(prices.maxValue)
        changeRouter(
          router,
          [MIN_PRICE, MAX_PRICE],
          [prices.minValue.toString(), prices.maxValue.toString()],
          ['page']
        )
      }}
      onClear={() => {
        setMinPrice(undefined)
        setMaxPrice(undefined)
        changeRouter(router, undefined, undefined, [MIN_PRICE, MAX_PRICE, 'page'])
      }}
      label={label}
    />
  )
}
