interface ColumnData {
  header: string | (() => React.JSX.Element)
  accessor: string
  label: string
  cellClassName?: string
}

export const getColumnData = (
  columnData: ColumnData[],
  cellHeaderClassName: string,
  getCellContent: (row: any, rowHeader: string) => '' | React.JSX.Element
) => {
  return columnData.map(({ header, accessor, label, cellClassName = '' }) => ({
    Header: header,
    accessor: accessor,
    cellHeaderClassName: `${cellHeaderClassName} ${cellClassName}`,
    cellClassName: 'h-[64px]',
    Cell: ({ row }: any) => getCellContent(row, label)
  }))
}
