export const invoiceTypeLabels = {
  0: 'None',
  1: 'Invoice',
  2: 'Credit'
}

export const invoiceStatusData = {
  0: { variant: 'neutral', label: 'NONE' },
  1: { variant: 'success', label: 'PAID' },
  2: { variant: 'warning-2', label: 'CURRENT' },
  3: { variant: 'danger', label: 'OVERDUE' },
  4: { variant: 'primary', label: 'CREDIT NOTE' },
  5: { variant: 'warning-1', label: 'PROCESSING' }
}
