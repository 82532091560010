import { useMutation } from '@tanstack/react-query'
import { httpPOST } from 'lib/http'

export interface IDownloadInvoicesRequest {
  invoiceList: Array<string>
}

export interface IDownloadInvoicesResponse {
  messageTitle: string | null
  messageDescription: string | null
  messageType: string
  data: IDownloadInvoices
}

export interface IDownloadInvoices {
  fileContent: string
  fileName: string
  fileSizeInKB: number
  fileSizeInMB: number
  mergeFileDetails: [
    {
      invoice: string
      avalibility: boolean
    }
  ]
  mimeType: string
}

export const downloadInvoices = async (req: IDownloadInvoicesRequest) => {
  const response = await httpPOST({
    url: `invoice/download_invoices`,
    body: req
  })
  return response?.data as IDownloadInvoicesResponse
}

export const useDownloadInvoices = () => {
  return useMutation({
    mutationFn: downloadInvoices
  })
}
