import { useSearchParams } from 'next/navigation'
import { useRouter } from 'next/router'
import React, { FC, useMemo } from 'react'
import { Button, EmptyRecords, Lozenge, LozengeProps, priceFormat } from '@nzsb/shopnx-ui'
import { IOrderHistoryData } from 'lib/actions'
import { useAppContext } from 'lib/contexts/app-context'
import { orderStatusData, orderTypeLabels } from 'lib/static/orderHistoryStaticData'
import { changeRouter, convertDate, getUserType } from 'lib/utilities'

import { DataTable } from '../DataTable'

export interface IOrderHistoryDataTableProps {
  componentId?: string
  orderHistoryData: IOrderHistoryData
  isLoading?: boolean
}

export const OrderHistoryDataTable: FC<IOrderHistoryDataTableProps> = ({
  componentId,
  orderHistoryData,
  isLoading
}: IOrderHistoryDataTableProps) => {
  const router = useRouter()
  const params = useSearchParams()
  const pageSizeParam = params.get('pageSize') || '10'
  const dateSortParam = params.get('sortType') || 'desc'
  const { user } = useAppContext()
  const userType = getUserType(user?.data?.roles)

  const onOrderHistoryView = (orderPadId: string) => {
    router.push({
      pathname: `/my-account/my-orders/${orderPadId}`
    })
  }

  const dateHeader = () => {
    return (
      <button
        className='flex gap-1 items-center'
        onClick={() => {
          changeRouter(
            router,
            ['sort', 'sortType'],
            ['date', dateSortParam === 'desc' ? 'asc' : 'desc']
          )
        }}>
        Date{' '}
        <i
          className={`flex items-center justify-center h-4 w-4 font-700 ${dateSortParam === 'desc' ? 'nzsbi-arrow-down' : 'nzsbi-arrow-up'}`}
        />
      </button>
    )
  }

  const getCellContent = (row: any, rowHeader: string) => {
    switch (rowHeader) {
      case 'Date':
        return <span className='text-N-600 font-500'>{convertDate(`${row?.original?.date}Z`)}</span>
      case 'Customer PO Reference':
        return (
          <a
            className='text-O-500 underline'
            href={`/my-account/my-orders/${row.original.orderNumber}`}>
            {row.original.purchaseOrderReference}
          </a>
        )
      case 'Order Number':
        return (
          <a
            className='text-O-500 underline'
            href={`/my-account/my-orders/${row.original.orderNumber}`}>
            {row.original.orderNumber}
          </a>
        )
      case 'Order Type':
        return (
          <span className='text-N-600 font-500'>
            {orderTypeLabels[row?.original?.orderType as keyof typeof orderTypeLabels]}
          </span>
        )
      case 'Amount':
        return <span className='text-N-600 font-500'>${priceFormat(row?.original?.amount)}</span>
      case 'Status':
        return (
          <div className='flex items-center'>
            <Lozenge
              appearance='subtle'
              variant={
                orderStatusData[row.original.status as keyof typeof orderStatusData]
                  ?.variant as LozengeProps['variant']
              }>
              {orderStatusData[row.original.status as keyof typeof orderStatusData]?.label}
            </Lozenge>
          </div>
        )
      case 'Action':
        return (
          <div className='flex items-center justify-center'>
            <Button
              componentId='my-order-history-view'
              size='xs'
              appearance='secondary-orange'
              onClick={() => {
                onOrderHistoryView(row?.original?.orderNumber)
              }}>
              View
            </Button>
          </div>
        )
      case 'Mobile':
        return (
          <div className='flex flex-col text-sm font-sans py-1'>
            <div className='flex items-center py-1'>
              <span className='w-[162px] text-N-500 font-400'>Date:</span>
              <span className='text-N-600 font-500'>{convertDate(`${row?.original?.date}Z`)}</span>
            </div>
            <div className='flex items-center py-1'>
              <span className='w-[162px] text-N-500 font-400'>Customer PO Reference:</span>
              <a
                className='text-O-500 underline'
                href={`/my-account/my-orders/${row.original.orderNumber}`}>
                {row.original.purchaseOrderReference}
              </a>
            </div>
            <div className='flex items-center py-1'>
              <span className='w-[162px] text-N-500 font-400'>Order Number:</span>
              <a
                className='text-O-500 underline'
                href={`/my-account/my-orders/${row.original.orderNumber}`}>
                {row.original.orderNumber}
              </a>
            </div>
            {userType === 'linked' && (
              <div className='flex items-center py-1'>
                <span className='w-[162px] text-N-500 font-400'>Order Type:</span>
                <span className='text-N-600 font-500'>
                  {orderTypeLabels[row?.original?.orderType as keyof typeof orderTypeLabels]}
                </span>
              </div>
            )}
            <div className='flex items-center py-1'>
              <span className='w-[162px] text-N-500 font-400'>Amount:</span>
              <span className='text-N-600 font-500'>${priceFormat(row?.original?.amount)}</span>
            </div>
            <div className='flex items-center py-1'>
              <span className='w-[162px] text-N-500 font-400'>Status:</span>
              <Lozenge
                appearance='subtle'
                variant={
                  orderStatusData[row.original.status as keyof typeof orderStatusData]
                    ?.variant as LozengeProps['variant']
                }>
                {orderStatusData[row.original.status as keyof typeof orderStatusData]?.label}
              </Lozenge>
            </div>
            <div className='flex items-center py-1'>
              <span className='w-[162px] text-N-500 font-400'>Action:</span>
              <Button
                className='w-[168px]'
                componentId='my-order-history-view'
                size='xs'
                appearance='secondary-orange'
                onClick={() => {
                  onOrderHistoryView(row?.original?.orderNumber)
                }}>
                View
              </Button>
            </div>
          </div>
        )
      default:
        return ''
    }
  }

  const cellHeaderClassName = `leading-[16px] h-[44px] z-0 !bg-N-50 ${userType === 'linked' ? 'min-w-[197px]' : 'min-w-[237px]'}`

  const DESKTOP_COLUMNS = useMemo(() => {
    return [
      {
        Header: () => dateHeader(),
        accessor: 'date',
        cellHeaderClassName: `${cellHeaderClassName}`,
        Cell: ({ row }: any) => {
          return getCellContent(row, 'Date')
        }
      },
      {
        Header: 'Customer PO Reference',
        accessor: 'purchaseOrderReference',
        cellHeaderClassName: `${cellHeaderClassName}`,
        Cell: ({ row }: any) => {
          return getCellContent(row, 'Customer PO Reference')
        }
      },
      {
        Header: 'Order Number',
        accessor: 'orderNumber',
        cellHeaderClassName: `${cellHeaderClassName}`,
        Cell: ({ row }: any) => {
          return getCellContent(row, 'Order Number')
        }
      },
      ...(userType === 'linked'
        ? [
            {
              Header: 'Order Type',
              accessor: 'orderType',
              cellHeaderClassName: `${cellHeaderClassName}`,
              Cell: ({ row }: any) => {
                return getCellContent(row, 'Order Type')
              }
            }
          ]
        : []),
      {
        Header: 'Amount',
        accessor: 'amount',
        cellHeaderClassName: `${cellHeaderClassName}`,
        Cell: ({ row }: any) => {
          return getCellContent(row, 'Amount')
        }
      },
      {
        Header: 'Status',
        accessor: 'status',
        cellHeaderClassName: `${cellHeaderClassName}`,
        Cell: ({ row }: any) => {
          return getCellContent(row, 'Status')
        }
      },
      {
        Header: 'Action',
        accessor: 'action',
        cellHeaderClassName: `${cellHeaderClassName} !min-w-[90px] !max-w-[91px]`,
        Cell: ({ row }: any) => {
          return getCellContent(row, 'Action')
        }
      }
    ]
  }, [userType, dateSortParam])

  const MOBILE_COLUMNS = useMemo(() => {
    return [
      {
        Header: ' ',
        accessor: ' ',
        Cell: ({ row }: any) => {
          return getCellContent(row, 'Mobile')
        }
      }
    ]
  }, [userType])

  const tableProps = {
    isOrderHistoryTable: true,
    enablePagination: true,
    data: orderHistoryData?.orders || [],
    showingCount: orderHistoryData?.orders?.length || 0,
    totalCount: orderHistoryData?.resultCount,
    onPerPageChange: (perPage: number) => {
      changeRouter(router, 'pageSize', perPage.toString())
    },
    defaultSelectedOption: pageSizeParam,
    paginationProps: {
      pageIndex: orderHistoryData?.pageIndex,
      pageSize: orderHistoryData?.pageSize,
      resultsCount: orderHistoryData?.resultCount,
      totalPages: orderHistoryData?.totalPages,
      limitedPerPageCount: true,
      onPageChange: (index: number) => {
        changeRouter(router, 'page', index.toString())
      }
    }
  }

  return (
    <div className='flex w-full h-fit' data-component-id={`order-history-table-${componentId}`}>
      {/* Desktop View */}
      <DataTable
        mainDivClassName='hidden lg:!block'
        columns={DESKTOP_COLUMNS}
        {...tableProps}
        isLoading={isLoading}
        emptyStateContent={
          <div className='w-full flex flex-col justify-start items-center h-fit pt-20'>
            <EmptyRecords />
            <span className='font-800 text-h3'>No records found</span>
            <span className='text-sm text-N-700'>
              Sorry! There are currently no orders to display.
            </span>
          </div>
        }
      />
      {/* Mobile, Tab View */}
      <DataTable
        mainDivClassName='lg:!hidden'
        isShowHeaders={false}
        columns={MOBILE_COLUMNS}
        isLoading={isLoading}
        emptyStateContent={
          <div className='w-full flex flex-col justify-start items-center h-fit pt-20'>
            <EmptyRecords />
            <span className='font-800 text-h3'>No records found</span>
            <span className='text-sm text-N-700'>
              Sorry! There are currently no orders to display.
            </span>
          </div>
        }
        {...tableProps}
      />
    </div>
  )
}

export default OrderHistoryDataTable
