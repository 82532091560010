import { useSearchParams } from 'next/navigation'
import { useRouter } from 'next/router'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { AlertProps, EmptyRecords, Lozenge, LozengeProps, Toast } from '@nzsb/shopnx-ui'
import { statementStatusData } from 'lib/static/invoiceStatementStaticData'
import { changeRouter, convertDate, getColumnData } from 'lib/utilities'

import { ActionButtonPanel, DataTable, DateHeader } from 'components/molecules'

export interface StatementsDataTableProps {
  componentId?: string
  statementListData: {
    resultCount: number
    pageIndex: number
    pageSize: number
    totalPages: number
    statementList: {
      date: string
      name: string
      status: 1 | 2 | 3
      attachmentName: string
      customerId: string
      customerEmail: string
      attachmentPath: string
      isPdfAvailable: string
    }[]
  }
  isLoading?: boolean
  onDownloadButtonClick: (name: string) => void
  onEmailButtonClick: (name?: string) => void
  onPayNowButtonClick: (name: string) => void
  onViewClick: (name: string) => void
  selectedRows: string[]
  setSelectedRows: any
}

interface ActionButtonsProps {
  statementName: string
  statementStatus: number
  isPdfAvailable: boolean
  onDownloadButtonClick: (name: string) => void
  onEmailButtonClick: (name?: string) => void
  onPayNowButtonClick: (name: string) => void
  onViewClick: (name: string) => void
}

const ActionButtons: FC<ActionButtonsProps> = ({
  statementName,
  statementStatus,
  isPdfAvailable,
  onDownloadButtonClick,
  onEmailButtonClick,
  onPayNowButtonClick,
  onViewClick
}: ActionButtonsProps) => {
  return (
    <ActionButtonPanel
      componentId={statementName}
      viewButton={{
        onClick: () => onViewClick(statementName),
        disabled: !isPdfAvailable
      }}
      emailButton={{
        onClick: () => onEmailButtonClick(statementName),
        disabled: !isPdfAvailable
      }}
      downloadButton={{
        onClick: () => onDownloadButtonClick(statementName),
        disabled: !isPdfAvailable
      }}
      payNowButton={{
        disabled: [1].includes(statementStatus),
        onClick: () => onPayNowButtonClick(statementName)
      }}
    />
  )
}

export const StatementsDataTable: FC<StatementsDataTableProps> = ({
  componentId,
  statementListData,
  isLoading,
  onDownloadButtonClick,
  onEmailButtonClick,
  onPayNowButtonClick,
  onViewClick,
  selectedRows,
  setSelectedRows
}: StatementsDataTableProps) => {
  const router = useRouter()
  const params = useSearchParams()
  const pageSizeParam = params.get('pageSize') || '30'
  const dateSortParam = params.get('sortType') || 'desc'
  const notify = (props: AlertProps) => Toast(props)

  const getCellContent = (row: any, rowHeader: string) => {
    switch (rowHeader) {
      case 'date':
        return <span className='text-N-600 font-500'>{convertDate(`${row?.original?.date}Z`)}</span>
      case 'name':
        return <span className='text-N-600 font-500'>{row?.original?.name}</span>
      case 'status':
        return (
          <div className='flex items-center'>
            <Lozenge
              appearance='subtle'
              variant={
                statementStatusData[row.original.status as keyof typeof statementStatusData]
                  ?.variant as LozengeProps['variant']
              }>
              {statementStatusData[row.original.status as keyof typeof statementStatusData]?.label}
            </Lozenge>
          </div>
        )
      case 'action':
        return (
          <div className='flex items-center justify-center'>
            <ActionButtons
              statementName={row.original.name}
              statementStatus={row.original.status}
              onDownloadButtonClick={onDownloadButtonClick}
              onEmailButtonClick={onEmailButtonClick}
              onPayNowButtonClick={onPayNowButtonClick}
              onViewClick={onViewClick}
              isPdfAvailable={row.original.isPdfAvailable}
            />
          </div>
        )
      case 'Mobile':
        return (
          <div className='flex flex-col text-sm font-sans py-1'>
            <div className='flex items-center py-1'>
              <span className='w-[108px] text-N-500 font-400'>Date:</span>
              <span className='text-N-600 font-500'>{convertDate(`${row?.original?.date}Z`)}</span>
            </div>
            <div className='flex items-center py-1'>
              <span className='w-[108px] text-N-500 font-400'>Name:</span>
              <span className='text-N-600 font-500'>{row?.original?.name}</span>
            </div>

            <div className='flex items-center py-1'>
              <span className='w-[108px] text-N-500 font-400'>Status:</span>
              <Lozenge
                appearance='subtle'
                variant={
                  statementStatusData[row.original.status as keyof typeof statementStatusData]
                    ?.variant as LozengeProps['variant']
                }>
                {
                  statementStatusData[row.original.status as keyof typeof statementStatusData]
                    ?.label
                }
              </Lozenge>
            </div>

            <div className='flex items-center py-1'>
              <span className='w-[108px] text-N-500 font-400'>Action:</span>
              <ActionButtons
                isPdfAvailable={row.original.isPdfAvailable}
                statementName={row.original.name}
                statementStatus={row.original.status}
                onDownloadButtonClick={onDownloadButtonClick}
                onEmailButtonClick={onEmailButtonClick}
                onPayNowButtonClick={onPayNowButtonClick}
                onViewClick={onViewClick}
              />
            </div>
          </div>
        )
      default:
        return ''
    }
  }

  const cellHeaderClassName = `leading-[16px] h-[44px] z-0 !bg-N-50`

  const DESKTOP_COLUMNS = useMemo(() => {
    const columnData = [
      {
        header: () => DateHeader(),
        accessor: 'date',
        label: 'date',
        cellClassName: '!min-w-[186px]'
      },
      {
        header: 'Name',
        accessor: 'name',
        label: 'name',
        cellClassName: '!min-w-[423px]'
      },
      {
        header: 'Status',
        accessor: 'status',
        label: 'status',
        cellClassName: '!min-w-[421px] max-w-[422px]'
      },
      {
        header: 'Action',
        accessor: 'action',
        label: 'action',
        cellClassName: '!min-w-[200px]'
      }
    ]

    return getColumnData(columnData, cellHeaderClassName, getCellContent)
  }, [dateSortParam])

  const MOBILE_COLUMNS = useMemo(() => {
    return [
      {
        Header: ' ',
        accessor: ' ',
        Cell: ({ row }: any) => {
          return getCellContent(row, 'Mobile')
        }
      }
    ]
  }, [])

  const [selectedOriginalRowsIndexes, setSelectedOriginalRowsIndexes] = useState<any>()

  const onRowSelect = (row: any) => {
    if (row.rowSelected) {
      setSelectedRows((prevSelectedRows: any) => {
        if (prevSelectedRows?.length < 30) {
          return [...prevSelectedRows, row]
        } else {
          notify({
            status: 'Info',
            alertHeader: 'Selection Limit Reached',
            alertBody:
              'You have reached the maximum number of records for performing a bulk action.'
          })
          return [...prevSelectedRows]
        }
      })
    } else {
      setSelectedRows((prevSelectedRows: any) =>
        prevSelectedRows.filter((item: any) => item?.name !== row?.name)
      )
    }
  }

  useEffect(() => {
    const selectedRowsIndexes: any = []
    const selectedStatementNumbers = selectedRows
      ?.filter((row: any) => row?.rowSelected)
      ?.map((row: any) => row?.name)

    statementListData?.statementList?.forEach((item: any, index: number) => {
      if (selectedStatementNumbers?.includes(item?.name)) {
        selectedRowsIndexes?.push(index.toString())
      }
    })
    setSelectedOriginalRowsIndexes(selectedRowsIndexes)
  }, [statementListData, selectedRows])

  const tableProps = {
    isOrderHistoryTable: true,
    enablePagination: true,
    data: statementListData?.statementList || [],
    showingCount: statementListData?.statementList?.length || 0,
    totalCount: statementListData?.resultCount,
    onPerPageChange: (perPage: number) => {
      changeRouter(router, 'pageSize', perPage.toString())
    },
    defaultSelectedOption: pageSizeParam,
    perPageSelections: ['30', '60', '90'],
    paginationProps: {
      pageIndex: statementListData?.pageIndex,
      pageSize: statementListData?.pageSize,
      resultsCount: statementListData?.resultCount,
      totalPages: statementListData?.totalPages,
      limitedPerPageCount: true,
      onPageChange: (index: number) => {
        changeRouter(router, 'page', index.toString())
      }
    },
    enableRowSelection: true,
    onRowSelect: (row: any) => onRowSelect(row),
    selectedRowIds: selectedOriginalRowsIndexes
  }

  return (
    <div className='flex w-full h-fit' data-component-id={`order-statement-table-${componentId}`}>
      {/* Desktop View */}
      <DataTable
        mainDivClassName='hidden lg:!block'
        columns={DESKTOP_COLUMNS}
        {...tableProps}
        isLoading={isLoading}
        emptyStateContent={
          <div className='w-full flex flex-col justify-start items-center h-fit pt-20'>
            <EmptyRecords />
            <span className='font-800 text-h3'>No records found</span>
            <span className='text-sm text-N-700'>
              Sorry! There are currently no statements to display.
            </span>
          </div>
        }
      />
      {/* Mobile, Tab View */}
      <DataTable
        mainDivClassName='lg:!hidden'
        isShowHeaders={false}
        columns={MOBILE_COLUMNS}
        isLoading={isLoading}
        emptyStateContent={
          <div className='w-full flex flex-col justify-start items-center h-fit pt-20'>
            <EmptyRecords />
            <span className='font-800 text-h3'>No records found</span>
            <span className='text-sm text-N-700'>
              Sorry! There are currently no statements to display.
            </span>
          </div>
        }
        {...tableProps}
      />
    </div>
  )
}

export default StatementsDataTable
