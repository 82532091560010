import React, { FC } from 'react'
import { Button } from '@nzsb/shopnx-ui'
import CN from 'classnames'

export interface ActionButtonProps {
  onClick: () => void
  disabled?: boolean
}

export interface ActionButtonPanelProps {
  className?: string
  componentId?: string
  downloadButton?: ActionButtonProps
  emailButton?: ActionButtonProps
  payNowButton?: ActionButtonProps
  viewButton?: ActionButtonProps
}

export const ActionButtonPanel: FC<ActionButtonPanelProps> = ({
  className,
  componentId,
  downloadButton,
  emailButton,
  payNowButton,
  viewButton
}) => {
  return (
    <div className={CN('flex gap-[8px]', className)}>
      {viewButton && (
        <Button
          appearance='secondary-orange'
          className='flex justify-center items-center !h-[36px] !w-[36px] !p-0'
          componentId={`action-panel-view-btn-${componentId}`}
          disabled={viewButton.disabled}
          iconBefore='nzsbi-eye'
          iconOnly
          onClick={viewButton.onClick}
          size='md'
        />
      )}
      {emailButton && (
        <Button
          appearance='secondary-orange'
          className='flex justify-center items-center !h-[36px] !w-[36px] !p-0'
          componentId={`action-panel-email-btn-${componentId}`}
          disabled={emailButton.disabled}
          iconBefore='nzsbi-email'
          iconOnly
          onClick={emailButton.onClick}
          size='md'
        />
      )}
      {downloadButton && (
        <Button
          appearance='secondary-orange'
          className='flex justify-center items-center !h-[36px] !w-[36px] !p-0'
          componentId={`action-panel-download-btn-${componentId}`}
          disabled={downloadButton.disabled}
          iconBefore='nzsbi-download'
          iconOnly
          onClick={downloadButton.onClick}
          size='md'
        />
      )}
      {payNowButton && (
        <Button
          appearance='secondary-orange'
          className='flex justify-center items-center !h-[36px] !w-[36px] !p-0'
          componentId={`action-panel-pay-now-btn-${componentId}`}
          disabled={payNowButton.disabled}
          iconBefore='nzsbi-dollar-1'
          iconOnly
          onClick={payNowButton.onClick}
          size='md'
        />
      )}
    </div>
  )
}

export default ActionButtonPanel
