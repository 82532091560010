import { useSearchParams } from 'next/navigation'
import { useRouter } from 'next/router'
import { changeRouter } from 'lib/utilities'

export const DateHeader = () => {
  const router = useRouter()
  const params = useSearchParams()
  const dateSortParam = params.get('sortType') || 'desc'

  return (
    <button
      className='flex gap-1 items-center'
      onClick={() => {
        changeRouter(
          router,
          ['sort', 'sortType'],
          ['date', dateSortParam === 'desc' ? 'asc' : 'desc']
        )
      }}>
      Date{' '}
      <i
        className={`flex items-center justify-center h-4 w-4 font-700 ${dateSortParam === 'desc' ? 'nzsbi-arrow-down' : 'nzsbi-arrow-up'}`}
      />
    </button>
  )
}
