import Image from 'next/image'
import Link from 'next/link'
import React, { FC } from 'react'
import { Button } from '@nzsb/shopnx-ui'
import CN from 'classnames'
import AppleButton from 'public/apple.svg'
import GoogleButton from 'public/google.svg'
import { checkURL } from 'lib/utilities'

import { NextImage } from 'components/atoms'
export interface AboutUsCompanyProfileBlockProps {
  actionButton?: {
    buttonText: string
    url?: string
    iconBefore?: string
    iconAfter?: string
  }
  appleButtonUrl?: string
  // type 1 -> without subheader
  // type 4 -> with button
  // type 5 -> with image
  block?: 1 | 4 | 5
  className?: string
  componentId?: string
  description?: string
  googleButtonUrl?: string
  header?: string
  imageData?: {
    data: {
      id: string
      attributes: {
        alternativeText?: string
        url: string
        provider?: any
        provider_metadata: any
        name: string
      }
    }
    url?: string
  }
  subHeader?: string
}

export const AboutUsCompanyProfileBlock: FC<AboutUsCompanyProfileBlockProps> = ({
  actionButton,
  block = 1,
  className,
  componentId,
  description,
  header,
  imageData,
  appleButtonUrl,
  googleButtonUrl,
  subHeader,
  ...restProps
}: AboutUsCompanyProfileBlockProps) => {
  const AboutUsCompanyProfileBlockClasses = CN(
    `${componentId}-about-us-company-profile flex flex-col bg-white w-full`,
    className
  )

  const {
    originalUrl: buttonOriginalUrl,
    restUrlWithQuery: buttonQuery,
    isInsideUrl: buttonUrlIsInternal
  } = checkURL(actionButton?.url ?? '')

  const {
    originalUrl: imageOriginalUrl,
    restUrlWithQuery: imageQuery,
    isInsideUrl: imageUrlIsInternal
  } = checkURL(imageData?.url ?? '')

  return (
    <div className={AboutUsCompanyProfileBlockClasses} {...restProps}>
      <div
        className={CN('w-full border-t-2 border-B-500 pt-4', {
          'flex flex-col':
            block !== 5 || !imageData?.data?.attributes?.provider_metadata?.public_id,
          'lg:!grid lg:!grid-cols-2 gap-8 !flex flex-col-reverse':
            block === 5 && imageData?.data?.attributes?.provider_metadata?.public_id
        })}>
        <div className='flex flex-col gap-8 font-sans'>
          <div className='flex flex-col gap-1'>
            {/* header */}
            {header && <div className='text-B-500 font-700 text-h2-m md:!text-h2'>{header}</div>}

            {/* sub header in block 1*/}
            {block === 1 && subHeader && (
              <div className='text-N-800 font-600 text-h3-m md:!text-h3'>{subHeader}</div>
            )}
          </div>

          {/* description in block 1 as html content */}
          {block === 1 && description && (
            <div className='flex flex-col w-full text-N-700 font-400 text-base break-words max-w-full'>
              <div
                data-component-id='content-html-seo-description'
                dangerouslySetInnerHTML={{
                  __html: description
                }}
                className='prose prose-blue-bold mark-down max-w-none'
              />
            </div>
          )}

          {/* description in block 4 and 5 */}
          {(block === 4 || block === 5) && description && (
            <div className='flex flex-col w-full text-N-700 font-400 text-base break-words max-w-full'>
              <div
                data-component-id='content-html-seo-description'
                dangerouslySetInnerHTML={{
                  __html: description
                }}
                className='prose prose-blue-bold mark-down max-w-none'
              />
            </div>
          )}

          {/* button in block 4 */}
          {block === 4 && actionButton?.buttonText && (
            <Link
              href={buttonUrlIsInternal ? buttonQuery : buttonOriginalUrl ?? {}}
              passHref
              target={buttonUrlIsInternal ? '' : '_blank'}
              className={CN({
                'cursor-pointer': buttonOriginalUrl,
                'cursor-auto pointer-events-none': !buttonOriginalUrl
              })}>
              <div className='flex justify-center'>
                <Button
                  appearance='primary-orange'
                  className='w-fit'
                  size='md'
                  iconBefore={actionButton?.iconBefore}
                  iconAfter={actionButton?.iconAfter}>
                  {actionButton?.buttonText}
                </Button>
              </div>
            </Link>
          )}
        </div>
        {block === 5 && imageData?.data?.attributes?.provider_metadata?.public_id && (
          <Link
            href={imageUrlIsInternal ? imageQuery : imageOriginalUrl ?? {}}
            passHref
            target={imageUrlIsInternal ? '' : '_blank'}
            className={CN({
              'cursor-pointer': imageOriginalUrl,
              'cursor-auto pointer-events-none': !imageOriginalUrl
            })}>
            <div className='flex justify-center items-center w-full h-[300px] lg:h-[432px] relative'>
              <NextImage
                data-component-id={`${componentId}-brand-home-image`}
                imgKey={
                  imageData?.data?.attributes?.alternativeText ??
                  imageData?.data?.attributes?.provider_metadata?.public_id
                }
                imageUrl={imageData?.data?.attributes?.provider_metadata?.public_id}
                system='CMS'
                useWithDimensions={false}
                alt={
                  imageData?.data?.attributes?.alternativeText ?? imageData?.data?.attributes?.name
                }
                {...restProps}
              />
            </div>
          </Link>
        )}
      </div>
      {block === 5 && (appleButtonUrl || googleButtonUrl) && (
        <div className='flex mt-8 gap-8'>
          {appleButtonUrl && (
            <Link href={appleButtonUrl || {}} passHref target='_blank' className='cursor-pointer'>
              <Image src={AppleButton} alt='Download on the App Store' />
            </Link>
          )}
          {googleButtonUrl && (
            <Link href={googleButtonUrl ?? {}} passHref target='_blank' className='cursor-pointer'>
              <Image src={GoogleButton} alt='Get it on Google Play' />
            </Link>
          )}
        </div>
      )}
    </div>
  )
}

export default AboutUsCompanyProfileBlock
