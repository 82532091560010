import React, { FC } from 'react'
import CN from 'classnames'

export interface InvoiceDetailsAddressCardProps {
  city?: string | null
  className?: string
  collectPersonDetails?: {
    contactNumber: string
    emailAddress: string
    firstName: string
    lastName: string
  }
  companyName?: string | null
  componentId?: string
  header?: string
  postCode?: string | null
  streetAddress?: string | null
  suburb?: string | null
}

export const InvoiceDetailsAddressCard: FC<InvoiceDetailsAddressCardProps> = ({
  city,
  className,
  collectPersonDetails,
  companyName,
  componentId,
  header,
  postCode,
  streetAddress,
  suburb
}: InvoiceDetailsAddressCardProps) => {
  return (
    <div
      className={CN(
        `${componentId}-address-card flex flex-col gap-y-[24px] w-full border border-N-200 px-[24px] py-[16px] bg-white items-start`,
        className
      )}>
      {header && <span className='text-B-500 text-base font-700'>{header}</span>}
      {companyName && (
        <span className='text-N-700 text-h3 tracking-[-0.48px] font-600 uppercase'>
          {companyName}
        </span>
      )}
      <div className='flex flex-col gap-[20px] text-N-700 text-base'>
        <div className='flex flex-col gap-[4px]'>
          {streetAddress && <span>{streetAddress}</span>}
          {!collectPersonDetails && (suburb || city) && (
            <span>
              {suburb}
              {suburb && ', '}
              {city}
            </span>
          )}
          {collectPersonDetails && (suburb || city) && (
            <>
              <span>{suburb}</span>
              <span>{city}</span>
            </>
          )}
          {postCode && <span>{postCode}</span>}
        </div>
        {collectPersonDetails && (
          <div className='flex flex-col gap-[4px]'>
            <span className='font-700'>Person to Collect Order</span>
            <span>
              {collectPersonDetails?.firstName} {collectPersonDetails?.lastName}
            </span>
            <span>{collectPersonDetails?.contactNumber}</span>
            <a
              href={`mailto:${collectPersonDetails?.emailAddress}`}
              className='text-base font-400 text-O-500 underline'>
              {collectPersonDetails?.emailAddress}
            </a>
          </div>
        )}
      </div>
    </div>
  )
}

export default InvoiceDetailsAddressCard
