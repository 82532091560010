import { useRouter } from 'next/router'
import React, { FC, useEffect, useState } from 'react'
import {
  AlertProps,
  Button,
  Drawer,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  priceFormat,
  ProdQuickCartCard,
  Spinner,
  Toast
} from '@nzsb/shopnx-ui'
import { useQueryClient } from '@tanstack/react-query'
import CN from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import {
  ICartItem,
  IGetCartDetails,
  useCXMLPunchOutCheckout,
  useGetCartItems,
  useUpdateCartItems
} from 'lib/actions'
import { useOciPunchOutCheckout } from 'lib/actions/checkout/useOciPunchOutCheckout'
import { useAppContext } from 'lib/contexts/app-context'
import { useModalContext } from 'lib/contexts/modal-context'
import { useSideBarContext } from 'lib/contexts/sideBar-context'
import { checkRoleBasedProperties } from 'lib/utilities'

import { NextImage } from 'components/atoms'
import { useWarnIfUnsavedChanges } from 'components/hooks'

import { EmptyCart } from '../EmptyCart'

export interface IQuickCartProps {
  className?: string
}

export const QuickCart: FC<IQuickCartProps> = ({ className, ...restProps }: IQuickCartProps) => {
  const QuickCartClasses = CN(`quick-cart`, className)
  const router = useRouter()

  const { quickCartSidebar } = useSideBarContext()
  const { isOpen: isOpenQuickCartSideBar, close: closeQuickCartSideBar } = quickCartSidebar

  const { cart, session, checkOut, user, isLoggedIn } = useAppContext()

  const { loginModal, saveCartModal } = useModalContext()

  /** */
  const [isSameInitialCart, setIsSameInitialCart] = useState(true)

  const [selectedItemToRemove, setSelectedItemToRemove] = useState<ICartItem>()
  const [isOpenRemoveModal, setIsOpenRemoveModal] = useState(false)
  const [isOpenDiscardSavings, setIsOpenDiscardSavings] = useState(false)
  const [isOpenSignInModal, setIsOpenSignInModal] = useState(false)
  const [routerPath, setRouterPath] = useState('')

  const { updateCartItemsAsync, isPending: isLoadingCartUpdate } = useUpdateCartItems()

  const { showGstPricePrimary, showShippingCharges } = checkRoleBasedProperties(user?.data?.roles)

  const { cxmlPunchOutCheckOutAsync, isPending: checkOutIsLoading } = useCXMLPunchOutCheckout()
  const { ociPunchOutCheckOutAsync, isPending: ociCheckOutIsLoading } = useOciPunchOutCheckout()

  const notify = (props: AlertProps) => Toast(props)

  // Opening remove modal
  const openRemoveModal = (cartItem: ICartItem) => {
    setIsOpenRemoveModal(true)
    setSelectedItemToRemove(cartItem)
  }

  // Closing remove modal
  const closeRemoveModal = () => {
    setIsOpenRemoveModal(false)
  }

  // Opening Sign in modal
  const openSignInModal = () => {
    setIsOpenSignInModal(true)
  }

  // Closing Sign in modal
  const closeSignInModal = () => {
    setIsOpenSignInModal(false)
  }

  // Opening discard savings modal
  const openDiscardSavings = (url?: string) => {
    if (!isSameInitialCart) {
      setIsOpenDiscardSavings(true)
      setRouterPath(url ?? '')
    } else if (isSameInitialCart) {
      closeQuickCartSideBar()
      if (url) {
        router.push(url)
      }
    } else if (!(ociCheckOutIsLoading || checkOutIsLoading || isLoadingCartUpdate)) {
      quickCartSidebar.close()
    }
    return false
  }

  // Closing discard savings modal
  const closeDiscardSavings = () => {
    setIsOpenDiscardSavings(false)
    setRouterPath('')
  }

  // Complete closing of quick cart sidebar
  const discardChanges = () => {
    closeDiscardSavings()
    closeQuickCartSideBar()
    if (routerPath !== '') {
      router.push(routerPath)
    }
  }

  const { isFetching: isFetchingCartItems, data: cartDetails } =
    useGetCartItems(isOpenQuickCartSideBar)

  const [cartDetailsSynced, setCartDetailsSynced] = useState<IGetCartDetails | undefined>(
    cartDetails?.data
  )
  // const previousCart = useStat(JSON.parse(JSON.stringify(cartDetails?.data?.cartItems || [])))
  const [previousCart, setPreviousCart] = useState(
    cartDetails?.data?.cartItems
      ? JSON.parse(JSON.stringify(cartDetails?.data?.cartItems))
      : undefined
  )

  useEffect(() => {
    if (!isFetchingCartItems) {
      setCartDetailsSynced(cartDetails?.data)
      setPreviousCart(JSON.parse(JSON.stringify(cartDetails?.data?.cartItems || [])))
    }
  }, [isFetchingCartItems])

  const updateTotalAmount = () => {
    const updatedCartDetails = { ...cartDetailsSynced }
    updatedCartDetails.cartItems = updatedCartDetails?.cartItems?.filter(
      cartItem => cartItem.id !== selectedItemToRemove?.id
    )

    return (updatedCartDetails.totalAmount =
      updatedCartDetails?.cartItems?.reduce(
        (acc, cartItem) => acc + cartItem?.priceWithGst * cartItem?.quantity,
        0
      ) || 0)
  }

  /** Remove items from the cart */
  const removeItemFromCart = () => {
    closeRemoveModal()
    const updatedCartDetails = { ...cartDetailsSynced }
    updatedCartDetails.cartItems = updatedCartDetails?.cartItems?.filter(
      cartItem => cartItem.id !== selectedItemToRemove?.id
    )

    updatedCartDetails.totalAmount = updateTotalAmount()

    updatedCartDetails.grossAmount =
      updatedCartDetails?.cartItems?.reduce(
        (acc, cartItem) => acc + cartItem?.price * cartItem?.quantity,
        0
      ) || 0

    updatedCartDetails.gstAmount =
      Number(updatedCartDetails?.totalAmount) -
      Number(updatedCartDetails?.grossAmount) +
      (showShippingCharges
        ? Number(updatedCartDetails?.shippingChargeWithGst) -
          Number(updatedCartDetails?.shippingCharge)
        : 0)

    setCartDetailsSynced(updatedCartDetails)
  }

  const [quantityExceedProducts, setQuantityExceedProducts] = useState<string[]>([])

  const [tempLoading, setTempLoading] = useState(true)

  /**
   * Update quantity of cart item
   * @param cartItemId
   * @param quantity
   */
  const updateQuantity = (cartItemId: string, quantity: number) => {
    const updatedCartDetails = { ...cartDetailsSynced }
    updatedCartDetails.cartItems = [...(updatedCartDetails?.cartItems || [])]
    updatedCartDetails.cartItems = updatedCartDetails?.cartItems?.map(cartItem => {
      if (cartItem.id === cartItemId) {
        cartItem.quantity = quantity
        cartItem.totalPriceWithGST = cartItem.priceWithGst * quantity
        cartItem.totalPrice = cartItem.price * quantity
      }
      return cartItem
    })

    updatedCartDetails.totalAmount = updateTotalAmount()

    updatedCartDetails.grossAmount =
      updatedCartDetails?.cartItems?.reduce((acc, cartItem) => acc + cartItem.totalPrice, 0) || 0
    updatedCartDetails.gstAmount =
      Number(updatedCartDetails?.totalAmount) -
      Number(updatedCartDetails?.grossAmount) +
      (showShippingCharges
        ? Number(updatedCartDetails?.shippingChargeWithGst) -
          Number(updatedCartDetails?.shippingCharge)
        : 0)
    setCartDetailsSynced(updatedCartDetails)
  }

  const queryClient = useQueryClient()

  const updateCartItemsAction = async (actionFrom: string) => {
    await queryClient.invalidateQueries({
      queryKey: ['GET_CART_ITEMS'],
      exact: true,
      refetchType: 'all'
    })
    const updatedRequest = {
      cartId: cart.cartId,
      cartItems:
        cartDetailsSynced?.cartItems?.map(item => ({
          productId: item.productId,
          variantId: item.variantId,
          quantity: item.quantity
        })) || []
    }
    setIsSameInitialCart(true)
    setQuantityExceedProducts([])
    setTempLoading(false)
    updateCartItemsAsync(updatedRequest)
      .then(res => {
        if (res?.data?.validationMessages) {
          const exceedProducts =
            cartDetailsSynced?.cartItems
              ?.filter(item => item.quantity > item.stock && item.stock !== 0 && item.isClearance)
              .map(item => item.id) || []
          setQuantityExceedProducts(exceedProducts)

          res?.data?.validationMessages?.map((error: any) => {
            notify({
              status: error?.messageType,
              alertHeader: error?.title,
              alertBody: error?.message
            })
          })
        }
        if (updatedRequest.cartItems.length === 0) {
          setCartDetailsSynced({ ...cartDetailsSynced, cartItems: [] })
        }

        if (routerPath !== '' && routerPath === '/cart') {
          router.push(routerPath)
        }
        if (actionFrom === 'discardModal') {
          closeDiscardSavings()
          closeQuickCartSideBar()
        }
      })
      .catch(() => {})
  }

  const emptyCartAction = () => {
    closeQuickCartSideBar()
  }

  useEffect(() => {
    setPreviousCart(JSON.parse(JSON.stringify(cartDetailsSynced?.cartItems || [])))
  }, [isOpenQuickCartSideBar])

  useEffect(() => {
    const newCartComparisonComponent = cartDetailsSynced?.cartItems?.map(item => ({
      sku: item.sku,
      quantity: item.quantity
    }))
    const oldCartComparisonComponent = previousCart?.map((item: any) => ({
      sku: item.sku,
      quantity: item.quantity
    }))

    if (cartDetailsSynced?.cartItems) {
      setIsSameInitialCart(
        JSON.stringify(newCartComparisonComponent || []) ===
          JSON.stringify(oldCartComparisonComponent || [])
      )
    } else {
      setIsSameInitialCart(true)
    }
  }, [cartDetailsSynced, previousCart])

  const checkOutCxml = async () => {
    const response = await cxmlPunchOutCheckOutAsync({
      cartId: cart.cartId
    })
    console.log('Return from BE CXML >>>> ', response?.data?.cxmlDocument)

    /** CXML response handling */
    if (
      response?.messageType === 'success' &&
      response?.data?.cxmlDocument &&
      response?.data?.cxmlDocument !== ''
    ) {
      console.log('CXML response handling', response?.data?.cxmlDocument)
      checkOut.setCxmlData(response?.data?.cxmlDocument)
      closeQuickCartSideBar()
      router.push('/punchOut/Successful')
    } else {
      closeQuickCartSideBar()
      router.push('/punchOut/Error')
    }
  }

  const checkOutOci = async () => {
    const response = await ociPunchOutCheckOutAsync({
      CartId: cart.cartId
    })
    console.log('Return from BE OCI >>>> ', response)

    /** OCI response handling */
    if (response?.messageType === 'success' && response?.data && response?.data?.length > 0) {
      console.log('OCI response handling', response?.data?.data)
      checkOut.setOciData(response?.data)
      closeQuickCartSideBar()
      router.push('/punchOut/Successful')
    } else {
      closeQuickCartSideBar()
      router.push('/punchOut/Error')
    }
  }

  const onClickSaveCart = () => {
    if (isLoggedIn) {
      saveCartModal?.open()
    } else {
      openSignInModal()
    }
  }

  const onClickGoToViewCart = () => {
    if (isSameInitialCart) {
      closeQuickCartSideBar()
      router.push('/cart')
    } else {
      openDiscardSavings('/cart')
    }
  }

  const onClickSignIn = () => {
    closeSignInModal()
    router.push(
      {
        pathname: router.pathname,
        query: { ...router.query, isRedirect: false }
      },
      router.asPath,
      { shallow: true }
    )
    loginModal?.open()
  }

  /** Render Image with Next Image component */
  const RenderImage = (productData: any) => {
    return (
      <div className='relative h-[50px] w-[50px] md:h-[78px] md:w-[78px]'>
        <NextImage
          imgKey={`${productData?.sku}-${productData?.id}`}
          width={75}
          height={75}
          imageUrl={productData?.image?.url}
          alt={productData?.image?.alt || productData?.title}
        />
      </div>
    )
  }

  /** Opening confirm dialog box */
  useWarnIfUnsavedChanges(!isSameInitialCart, openDiscardSavings)

  const getStockMessage = (stock: number) =>
    stock > 1 ? `Only ${stock} items available in stock` : `Only ${stock} item available in stock`

  return (
    <div className={QuickCartClasses} {...restProps}>
      <Drawer
        disableHeader
        disableFooter
        componentId='quick-cart-drawer'
        size='lg'
        show={isOpenQuickCartSideBar}
        onOverlayClick={() => openDiscardSavings()}
        headerButtonProps={{ onClick: closeQuickCartSideBar }}
        className='!z-10'>
        {isFetchingCartItems && tempLoading ? (
          <div className='flex items-center justify-center w-full h-full'>
            <Spinner loop />
          </div>
        ) : (
          <>
            <div
              className={CN(
                'shadow-sm flex flex-col w-full',
                {
                  'h-[calc(100%-340px)] md:h-[calc(100%-184px)]': showShippingCharges,
                  'h-[calc(100%-320px)] md:h-[calc(100%-164px)]': !showShippingCharges
                },
                previousCart?.length === 0 && '!h-full'
              )}>
              {/* <div dangerouslySetInnerHTML={myHtml} /> */}
              {/** Header section */}
              <div className='p-[16px] md:p-[20px] shadow-sm'>
                <div className='flex flex-row justify-between'>
                  <div className='flex gap-[8px]'>
                    <i
                      className={`quick-cart-drawer-header-title-icon nzsbi-cart text-[24px] text-N-700`}
                    />
                    <span className='font-semibold quick-cart-drawer-header-title text-h3-m lg:text-h3 text-N-700'>
                      You have {cartDetailsSynced?.cartItems?.length || 0} item
                      {cartDetailsSynced?.cartItems?.length !== 1 ? 's' : ''} in your cart
                    </span>
                  </div>
                  <button onClick={() => openDiscardSavings()}>
                    <i className='quick-cart-drawer-header-title-close-icon nzsbi-close text-[20px] text-N-700' />
                  </button>
                </div>
                {/* Hidden temporality  */}
                {/* {session?.isPunchOut && (
                  <div className='flex flex-col lg:!flex-row md:flex-col gap-[16px] mt-[24px]'>
                    <ProgressBarWithLabel
                      className='w-full'
                      progressBar={{
                        filledPercentage: 20
                      }}
                      badge={{
                        appearance: 'subtle',
                        children: 'Catalogue Budget',
                        className: 'uppercase mb-[8px]',
                        icon: 'nzsbi-catalogue',
                        variant: 'neutral'
                      }}
                      price={{
                        current: 1456.76,
                        max: 5000
                      }}
                    />
                    <ProgressBarWithLabel
                      className='w-full'
                      progressBar={{
                        filledPercentage: 20
                      }}
                      badge={{
                        appearance: 'subtle',
                        children: 'Non-Catalogue Budget',
                        className: 'uppercase mb-[8px]',
                        icon: 'nzsbi-catalogue',
                        variant: 'neutral'
                      }}
                      price={{
                        current: 1456.76,
                        max: 5000
                      }}
                    />
                  </div>
                )} */}
              </div>

              {previousCart?.length === 0 ? (
                <EmptyCart
                  className='w-full h-full'
                  onClick={emptyCartAction}
                  componentId='quick-cart-empty-state'
                />
              ) : (
                <div className='flex flex-col w-full p-[20px] overflow-auto overflow-x-hidden scrollbar'>
                  <AnimatePresence>
                    {cartDetailsSynced?.cartItems?.map((item, index) => (
                      <motion.div
                        key={item.id}
                        initial={{ opacity: 0, x: -100 }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{ opacity: 0, x: 100, transition: { duration: 0.5 } }}>
                        <ProdQuickCartCard
                          key={item?.id}
                          componentId={`quick-cart-item-${index}`}
                          quantity={{
                            defaultValue: item?.quantity || 1,
                            onChange: (e: any, value: number) => {
                              updateQuantity(item?.id, value)
                            },
                            isZeroAllowed: false
                          }}
                          uom={item?.uom}
                          lozengeClass='!z-10'
                          productFlag={item?.productFlag}
                          isShowCatalogIcon={item?.isCatalogue}
                          isShowGstPricePrimary={showGstPricePrimary}
                          className='w-full border-b-[1px] border-N-100 py-[32px]'
                          title={item?.name}
                          currentPrice={item?.totalPrice}
                          withGst={item?.totalPriceWithGST}
                          eachPrice={item?.price}
                          eachPriceWithGst={item?.priceWithGst}
                          nextImage={RenderImage(item)}
                          sku={item?.sku}
                          onTitleClick={() => {
                            openDiscardSavings(`/product/${item?.urlSlug}`)
                          }}
                          remove={{
                            children: 'Remove',
                            onClick: () => {
                              if (item?.id) {
                                openRemoveModal(item)
                              }
                            }
                          }}
                          errorMessage={
                            quantityExceedProducts.includes(item?.id)
                              ? `Only ${item?.stock} ${getStockMessage(item?.stock)} available in stock`
                              : undefined
                          }
                        />
                      </motion.div>
                    ))}
                  </AnimatePresence>
                </div>
              )}
            </div>
            {previousCart?.length > 0 && (
              <div className='absolute bottom-0 flex flex-col w-full p-[20px] gap-[12px] shadow-sm text-N-700'>
                <div className='flex flex-col gap-[4px]'>
                  <div className='flex flex-row justify-between text-sm font-medium quick-cart-sub-total'>
                    <span className='quick-cart-sub-total-text'>Sub-total</span>
                    {cartDetailsSynced?.grossAmount && (
                      <span className='quick-cart-sub-total-value'>
                        ${priceFormat(cartDetailsSynced?.grossAmount)}
                      </span>
                    )}
                  </div>

                  {showShippingCharges && (
                    <div className='flex flex-row justify-between text-sm font-medium quick-cart-shipping-charges'>
                      <span className='quick-cart-sub-total-text'>Shipping Charges</span>

                      <span className='quick-cart-shipping-charges-value'>
                        ${priceFormat(cartDetailsSynced?.shippingCharge ?? 0)}
                      </span>
                    </div>
                  )}

                  <div className='flex flex-row justify-between text-sm font-medium quick-cart-gst'>
                    <span className='quick-cart-gst-text'>GST</span>
                    {cartDetailsSynced?.gstAmount && (
                      <span className='quick-cart-gst-value'>
                        ${priceFormat(cartDetailsSynced?.gstAmount)}
                      </span>
                    )}
                  </div>
                  <div className='flex flex-row justify-between font-bold quick-cart-order-total'>
                    {showGstPricePrimary ? (
                      <span className='quick-cart-order-total-text'>
                        Order Total (NZD incl.GST)
                      </span>
                    ) : (
                      <span className='quick-cart-order-total-text'>Order Total (NZD)</span>
                    )}
                    {cartDetailsSynced?.totalAmount && (
                      <span className='quick-cart-order-total-value'>
                        $
                        {showShippingCharges
                          ? priceFormat(
                              (cartDetailsSynced?.grossAmount || 0) +
                                (cartDetailsSynced?.shippingCharge || 0) +
                                (cartDetailsSynced?.gstAmount || 0)
                            )
                          : priceFormat(
                              (cartDetailsSynced?.grossAmount || 0) +
                                (cartDetailsSynced?.gstAmount || 0)
                            )}
                      </span>
                    )}
                  </div>
                </div>
                <div className='flex flex-col-reverse md:!flex-row w-full gap-[12px] md:gap-[16px]'>
                  <Button
                    componentId='quick-cart-save-cart-btn'
                    appearance='secondary-orange'
                    size='sm'
                    isBlock
                    onClick={onClickSaveCart}
                    disabled={!isSameInitialCart}>
                    SAVE CART
                  </Button>

                  <Button
                    componentId='quick-cart-view-cart-btn'
                    appearance='secondary-orange'
                    size='sm'
                    onClick={onClickGoToViewCart}
                    isBlock>
                    VIEW CART
                  </Button>
                  <Button
                    componentId='quick-cart-update-cart-btn'
                    appearance='primary-orange'
                    size='sm'
                    isBlock
                    isLoading={isLoadingCartUpdate}
                    disabled={isSameInitialCart}
                    onClick={updateCartItemsAction}>
                    UPDATE CART
                  </Button>
                  <Button
                    componentId={
                      session?.isPunchOut ? 'quick-cart-punchout-btn' : 'quick-cart-checkout-btn'
                    }
                    onClick={() => {
                      if (session?.punchOutMethod === 'cxml') {
                        checkOutCxml()
                      } else if (session?.punchOutMethod === 'oci') {
                        checkOutOci()
                      } else {
                        quickCartSidebar.close()
                        router.push('/checkout')
                      }
                    }}
                    appearance='primary-orange'
                    size='sm'
                    isBlock
                    disabled={!isSameInitialCart}
                    isLoading={checkOutIsLoading || ociCheckOutIsLoading}>
                    {session?.isPunchOut ? 'PUNCHOUT' : 'CHECKOUT'}
                  </Button>
                </div>
              </div>
            )}
            {/* <PunchOutForm
              cxmlHookURL={browserFormPostURL}
              xml={xml}
              cartItems={ociItemList}
              ociHookURL={ociHookURL}
              ociToken={ociToken}
            /> */}
          </>
        )}
      </Drawer>
      <Modal
        componentId='quick-cart-remove-item-confirmation'
        isOpen={isOpenRemoveModal}
        className='z-20 !max-w-[380px]'>
        <ModalHeader
          componentId='quick-cart-remove-item-confirmation'
          hasBorder={false}
          heading={null}
          onClose={closeRemoveModal}
        />
        <ModalBody componentId='quick-cart-remove-item-confirmation' className='p-[20px] pt-0'>
          <div className='flex flex-col items-center justify-center text-center quick-cart-modal-body-confirmation'>
            <i className='quick-cart-modal-body-confirmation-icon ri-error-warning-line text-[48px] text-O-500 mb-[16px]' />
            {/* <i className='ri-error-warning-li text-[48px] ' /> */}
            You are about to remove an item from your cart.
          </div>
        </ModalBody>
        <ModalFooter componentId='quick-cart-remove-item-confirmation'>
          <div className='flex gap-[16px]'>
            <Button
              componentId='quick-cart-remove-item-confirmation-cancel-btn'
              appearance='secondary-gray'
              onClick={closeRemoveModal}>
              CANCEL
            </Button>
            <Button
              componentId='quick-cart-remove-item-confirmation-confirm-btn'
              appearance='primary-orange'
              className='mr-2'
              onClick={removeItemFromCart}>
              REMOVE
            </Button>
          </div>
        </ModalFooter>
      </Modal>
      <Modal
        componentId='quick-cart-unsaved-warning'
        isOpen={isOpenDiscardSavings}
        className='z-20 !max-w-[380px]'>
        <ModalHeader
          componentId='quick-cart-unsaved-warning'
          hasBorder={false}
          heading={null}
          onClose={closeDiscardSavings}
        />
        <ModalBody componentId='quick-cart-unsaved-warning' className='!p-[20px] !pt-0'>
          <div className='flex flex-col items-center justify-center text-center quick-cart-modal-body-warning'>
            <i className='quick-cart-modal-body-warning-icon ri-error-warning-line text-[48px] text-O-500 mb-[16px]' />
            <span>There are unsaved changes in your cart.</span>
          </div>
        </ModalBody>
        <ModalFooter componentId='quick-cart-unsaved-warning'>
          <div className='flex gap-[16px]'>
            <Button
              componentId='quick-cart-unsaved-warning-discard-btn'
              appearance='secondary-gray'
              disabled={isLoadingCartUpdate}
              onClick={discardChanges}>
              Discard
            </Button>
            <Button
              componentId='quick-cart-unsaved-warning-update-btn'
              appearance='primary-orange'
              className='mr-2'
              isLoading={isLoadingCartUpdate}
              onClick={() => {
                updateCartItemsAction('discardModal')
              }}>
              Update Cart
            </Button>
          </div>
        </ModalFooter>
      </Modal>
      {/* Sign in Modal */}
      <Modal
        componentId='quick-cart-sign-in-modal'
        isOpen={isOpenSignInModal}
        className='z-20 !w-[380px]'>
        <ModalHeader
          componentId='quick-cart-sign-in-modal-header'
          hasBorder={false}
          heading={null}
          onClose={closeSignInModal}
        />
        <ModalBody componentId='quick-cart-sign-in-modal-body' className='p-[20px] !pt-0'>
          <div className='flex flex-col items-center justify-center text-center view-cart-modal-body-confirmation'>
            <i className='view-cart-modal-body-confirmation-icon ri-error-warning-line text-[48px] text-Y-500 mb-[16px]' />
            {/**/}
            To save this order you must first sign in
          </div>
        </ModalBody>
        <ModalFooter componentId='quick-cart-sign-in-modal-footer'>
          <div className='flex gap-[8px]'>
            <Button
              componentId='view-cart-clearcart-warning-discard-btn'
              appearance='secondary-gray'
              size='xs'
              onClick={closeSignInModal}>
              CANCEL
            </Button>
            <Button
              componentId='quick-cart-sign-in-modal-sign-in-btn'
              appearance='primary-orange'
              size='xs'
              onClick={onClickSignIn}>
              SIGN IN
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default QuickCart
