import { PermissionMenuProps } from 'types/myAccount'

export const ORDERING_MENU: PermissionMenuProps[] = [
  {
    id: 1,
    label: 'My Catalogue',
    href: '/my-account/ordering/my-catalogue',
    isLinkedCRAccountMenu: true,
    icon: 'nzsbi-catalogue',
    permissionName: 'MY_CATALOGUE',
    description: "Easily view and purchase items from your organisation's catalogue."
  },
  {
    id: 2,
    label: 'My Favourites',
    href: '/my-account/ordering/my-favourites',
    isLinkedCRAccountMenu: true,
    icon: 'nzsbi-heart',
    permissionName: 'MY_FAVOURITE',
    description: 'Save and access your regularly purchased items in one place.'
  },
  {
    id: 3,
    label: 'My Order Pad',
    href: '/my-account/ordering/my-orderpad',
    isLinkedCRAccountMenu: true,
    icon: 'nzsbi-save',
    permissionName: 'MY_ORDER_PAD',
    description:
      'View orders you have saved previously to complete your order or for easy re-ordering'
  },
  {
    id: 4,
    label: 'My Orders',
    href: '/my-account/my-orders',
    isLinkedCRAccountMenu: true,
    icon: 'nzsbi-history',
    cardIcon: 'nzsbi-history',
    permissionName: 'MY_ORDERS',
    description: 'View order history, open order status and track & trace.'
  },
  {
    id: 5,
    label: 'Orders Awaiting Approval',
    href: '/coming-soon',
    isLinkedCRAccountMenu: false,
    icon: 'nzsbi-hour-glass',
    permissionName: 'ORDERS_WAITING_APPROVAL',
    description: 'Check if the orders you have raised, have been actioned by your approver.'
  },
  {
    id: 6,
    label: 'Request Item for my catalogue',
    href: '/request-catalogue-item',
    isLinkedCRAccountMenu: false,
    icon: 'nzsbi-question-double-lined-rounded',
    cardIcon: 'nzsbi-question-double-lined-rounded',
    permissionName: 'REQUEST_ITEM_FOR_MY_CATELOGUE',
    description: 'Send a request for an item you would like added to your My Catalogue.'
  },
  {
    id: 7,
    label: 'Multi Site Ordering',
    href: '/coming-soon',
    isLinkedCRAccountMenu: false,
    icon: 'nzsbi-location-pin',
    permissionName: 'MULTI_SITE_ORDERING',
    description:
      'View and select multiple delivery sites and/or cost centres, you need to purchase for on behalf of your organisation.'
  }
]

export const FINANCE_MENU: PermissionMenuProps[] = [
  {
    id: 1,
    label: 'My Spend Limit',
    href: '/coming-soon',
    icon: 'nzsbi-dollar-1',
    cardIcon: 'nzsbi-dollar-1',
    permissionName: 'MY_SPEND_LIMIT',
    description:
      'Don’t be caught out at checkout, check the spend limit your organisation has allocated you here.'
  },
  {
    id: 2,
    label: 'Invoice / Statements',
    href: '/my-account/invoices-credits?statuses=Overdue',
    icon: 'nzsbi-document',
    permissionName: 'INVOICE_AND_STATEMENT',
    description: 'View, Download and Pay your Invoices and Statements here.'
  }
  // {
  //   id: 3,
  //   label: 'My Quotes',
  //   href: '/my-quotes',
  //   icon: 'nzsbi-checked-clipboard',
  //   permissionName: 'MY_QUOTES ***',
  //   description: ''
  // }
]

export const SETTINGS_MENU: PermissionMenuProps[] = [
  {
    id: 1,
    label: 'My Preferences',
    isLinkedCRAccountMenu: true,
    href: '/coming-soon',
    icon: 'nzsbi-spanner-screw-driver',
    permissionName: 'MY_PREFERENCES',
    description: 'Easily manage the email communications you receive from NZ Safety Blackwoods.'
  },
  {
    id: 2,
    label: 'Change Password',
    isLinkedCRAccountMenu: true,
    icon: 'nzsbi-lock',
    permissionName: 'CHANGE_PASSWORD',
    description:
      'Maintain and keep secure your password for accessing the NZ Safety Blackwoods website.'
  },
  {
    id: 3,
    label: 'Update Details',
    isLinkedCRAccountMenu: true,
    href: '/my-account/account-settings/update-details',
    icon: 'nzsbi-cog-1',
    permissionName: 'UPDATE_DETAILS',
    description:
      'Maintain your name and phone number and add additional shipping locations, for an easier checkout process'
  }
]
