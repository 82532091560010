import { useQuery } from '@tanstack/react-query'
import { httpGET } from 'lib/http'

export interface IMyCatalogueCategory {
  count: number
  id: number
  name: string
  urlSlug: string
}

export const getMyCatalogueCategories = async (context?: any) => {
  const { data } = await httpGET({
    url: `account/catalogue/getcategories`,
    context
  })
  return (data?.data || []) as IMyCatalogueCategory[]
}

export const useGetMyCatalogueCategories = () => {
  return useQuery({ queryKey: ['GET_CATALOGUE_CATEGORIES'], queryFn: getMyCatalogueCategories })
}
