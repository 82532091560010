import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { FC, useState } from 'react'
import { Accordion, Button } from '@nzsb/shopnx-ui'
import CN from 'classnames'
import { IAllCategory } from 'lib/actions'
import { useModalContext } from 'lib/contexts/modal-context'

import { MobileMenuSubHeader } from '../MobileMenuSubHeader'

export interface IMobileMenuProductTypeMenuProps {
  className?: string
  componentId?: string
  data?: IAllCategory | null
  setIsPillarMenuOpen?: any
}

export interface ITypesAccordionProps {
  categorySlug: string
  item: IAllCategory
  componentId: string
}

const TypesAccordion = ({ categorySlug, componentId, item }: ITypesAccordionProps) => {
  const router = useRouter()

  const [isOpen, setIsOpen] = useState(false)
  const { mainMenuSidePane } = useModalContext()

  const slug = router?.query?.slug?.[2] as string

  return (
    <Accordion
      key={item?.id}
      componentId={`${componentId}-accordion`}
      item={{
        ...item,
        title: item?.name,
        id: item?.id,
        content: (
          <div
            className='flex flex-col'
            data-component-id={`${componentId}-accordion-content-body`}>
            {Array?.isArray(item?.childCategories) &&
              item?.childCategories?.map((subCategory: IAllCategory, subIndex: number) => (
                <Link
                  href={`/category/${categorySlug}/${item?.urlSlug}/${subCategory?.urlSlug}`}
                  onClick={() => {
                    mainMenuSidePane.close()
                  }}
                  data-component-id={`${componentId}-sub-category-${subCategory?.id}`}
                  className={CN('text-N-800 !text-sm px-3 py-2 hover:bg-N-25 rounded w-full', {
                    'text-B-500 font-600': slug === subCategory?.urlSlug
                  })}
                  key={subCategory?.id || subIndex}>
                  {subCategory?.name}
                </Link>
              ))}

            {/** See all Btn in type level */}
            <Button
              className='w-fit px-3 py-2'
              isBlock={false}
              isRingOffset={false}
              onClick={() => {
                mainMenuSidePane.close()
                router.push(`/category/${categorySlug}/${item?.urlSlug}`)
              }}
              componentId={`${componentId}-item-${item?.id}-see-all-btn`}
              appearance='link-gray'>
              See All
            </Button>
          </div>
        )
      }}
      className='w-full !border-t-0 !border-b-0'
      headerBarClasses={CN('!py-2 !px-3', {
        '!bg-B-25 rounded-t': isOpen,
        '!bg-N-25 rounded': !isOpen
      })}
      headerClasses={CN('!normal-case !font-500 !text-sm ', {
        '!text-B-600': isOpen,
        '!text-N-800': !isOpen
      })}
      expanded={isOpen}
      setExpanded={setIsOpen}
      variant='white'
      isArrowIcons={false}
    />
  )
}

export const MobileMenuProductTypeMenu: FC<IMobileMenuProductTypeMenuProps> = ({
  className,
  componentId,
  data,
  setIsPillarMenuOpen,
  ...restProps
}: IMobileMenuProductTypeMenuProps) => {
  return (
    <div
      data-component-id={`${componentId}-section`}
      className='flex flex-col text-black'
      {...restProps}>
      <MobileMenuSubHeader
        subHeaderTitle={data?.name}
        onBackClick={() => {
          setIsPillarMenuOpen(true)
        }}
      />

      <div className='flex flex-col text-black mt-7 gap-y-3'>
        {Array?.isArray(data?.childCategories) &&
          data?.childCategories?.map((item: IAllCategory, index: number) => {
            return (
              <TypesAccordion
                categorySlug={data?.urlSlug}
                componentId={`${componentId}-type-item-${index}`}
                item={item}
                key={item?.id}
              />
            )
          })}
      </div>
    </div>
  )
}

export default MobileMenuProductTypeMenu
