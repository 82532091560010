import { useMutation } from '@tanstack/react-query'
import { httpPOST } from 'lib/http'

export interface IDownloadStatementsRequest {
  statementList: Array<string>
}

export interface IDownloadStatementsResponse {
  messageTitle: string | null
  messageDescription: string | null
  messageType: string
  data: IDownloadStatements
}

export interface IDownloadStatements {
  fileContent: string
  fileName: string
  fileSizeInKB: number
  fileSizeInMB: number
  mergeFileDetails: [
    {
      statement: string
      avalibility: boolean
    }
  ]
  mimeType: string
}

export const downloadStatements = async (req: IDownloadStatementsRequest) => {
  const response = await httpPOST({
    url: `statement/download_statements`,
    body: req
  })
  return response?.data as IDownloadStatementsResponse
}

export const useDownloadStatements = () => {
  return useMutation({
    mutationFn: downloadStatements
  })
}
