import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { FC } from 'react'
import { Button } from '@nzsb/shopnx-ui'
import CN from 'classnames'

import { OctagonWrapper } from 'components/atoms'

export interface ISpecialServicesCardProps {
  cardTitle: string
  childBlocks?: Array<{
    __typename: string
    Header: string
  }>
  className?: string
  icon: string
  urlSlug: string
}

export const SpecialServicesCard: FC<ISpecialServicesCardProps> = ({
  cardTitle,
  childBlocks,
  className,
  icon,
  urlSlug,
  ...restProps
}: ISpecialServicesCardProps) => {
  const MyAccountTitleCardClasses = CN(
    `bg-white border-[1px] border-N-100 rounded px-[20px] pb-[24px] w-full cursor-pointer`,
    className
  )

  const router = useRouter()

  return (
    <div
      data-component-id='all-product-category-card'
      className={MyAccountTitleCardClasses}
      {...restProps}>
      {icon && (
        <span>
          {/* Category Pillar tile icon section */}
          <OctagonWrapper
            componentId='all-product-category-card-icon'
            icon={icon}
            className='absolute flex items-center justify-center mt-[-24px]'
          />
        </span>
      )}

      {/** Category Pillar tile see all btn section */}
      <div className='w-full flex justify-end mt-[20px]'>
        <Button
          className='w-fit'
          isBlock={false}
          size='md'
          isRingOffset={false}
          iconAfter='nzsbi-chevron-right'
          onClick={() => {
            router.push(`/services/${urlSlug}`)
          }}
          componentId={`all-product-category-card-see-all-btn`}
          appearance='link'>
          See All
        </Button>
      </div>

      {/** Category pillar tile header section */}
      <div
        className='text-h3 font-semibold text-N-800 mb-[16px] mt-[16px] capitalize'
        data-component-id={`all-product-category-card-${cardTitle}`}>
        {cardTitle}
      </div>

      {/** Category Pillar tile section */}
      <div
        className='text-base font-normal text-N-700 flex flex-col gap-y-3'
        data-component-id='all-product-category-card-child-categories'>
        {childBlocks?.map(
          (
            childBlock: {
              __typename: string
              Header: string
            },
            subIndex: number
          ) => (
            <Link
              href={`/services/${urlSlug}/#${childBlock?.Header}`}
              data-component-id={`all-product-category-card-child-categories-${subIndex}`}
              className={CN(`hover:text-O-500`)}
              key={childBlock?.Header ?? subIndex}>
              {childBlock?.Header}
            </Link>
          )
        )}
      </div>
    </div>
  )
}

export default SpecialServicesCard
