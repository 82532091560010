import React, { FC } from 'react'
import { PickUpDetails } from 'lib/actions'

import { AddressProps } from '../AddressCard'
import { OrderHistoryAddressCard } from '../OrderHistoryAddressCard'

export interface OrderHistoryAddressSectionProps {
  invoiceAddress?: AddressProps
  pickupDetails?: PickUpDetails
  shippingAddress?: AddressProps
}

export const OrderHistoryAddressSection: FC<OrderHistoryAddressSectionProps> = ({
  invoiceAddress,
  pickupDetails,
  shippingAddress
}: OrderHistoryAddressSectionProps) => {
  return (
    <>
      {(shippingAddress || invoiceAddress || pickupDetails) && (
        <div className='flex flex-col gap-5 md:!grid md:!grid-cols-2 md:gap-8'>
          {invoiceAddress && (
            <OrderHistoryAddressCard
              header='Invoice Address'
              componentId='order-history-invoice-address'
              companyName={
                invoiceAddress?.businessName ||
                invoiceAddress?.attentionTo ||
                `${invoiceAddress?.firstName} ${invoiceAddress?.lastName}`
              }
              streetAddress={invoiceAddress?.streetAddress}
              suburb={invoiceAddress?.suburb}
              city={invoiceAddress?.city}
              postCode={invoiceAddress?.postCode}
            />
          )}
          {shippingAddress && (
            <OrderHistoryAddressCard
              header='Shipping Address'
              componentId='order-history-shipping-address'
              companyName={
                shippingAddress?.businessName ||
                shippingAddress?.attentionTo ||
                `${shippingAddress?.firstName} ${shippingAddress?.lastName}`
              }
              streetAddress={shippingAddress?.streetAddress}
              suburb={shippingAddress?.suburb}
              city={shippingAddress?.city}
              postCode={shippingAddress?.postCode}
            />
          )}
          {pickupDetails && (
            <OrderHistoryAddressCard
              header='Pick Up'
              componentId='order-history-pickup-details'
              companyName={pickupDetails?.branchName}
              streetAddress={pickupDetails?.streetAddress}
              suburb={pickupDetails?.suburb}
              city={pickupDetails?.city}
              postCode={pickupDetails?.postCode}
              collectPersonDetails={{
                firstName: pickupDetails?.firstName,
                lastName: pickupDetails?.lastName,
                emailAddress: pickupDetails?.emailAddress,
                contactNumber: pickupDetails?.contactNumber
              }}
            />
          )}
        </div>
      )}
    </>
  )
}

export default OrderHistoryAddressSection
