import React, { FC } from 'react'
import { priceFormat } from '@nzsb/shopnx-ui'
import CN from 'classnames'
import { useAppContext } from 'lib/contexts/app-context'
import { checkRoleBasedProperties } from 'lib/utilities'

export interface ISummeryDetailsCardProps {
  className?: string
  componentId: string
  gst: number
  isInvoiceDetails?: boolean
  shippingCharges: number
  subTotal: number
}

export const SummeryDetailsCard: FC<ISummeryDetailsCardProps> = ({
  className,
  componentId,
  gst,
  isInvoiceDetails = false,
  shippingCharges,
  subTotal
}: ISummeryDetailsCardProps) => {
  const { user } = useAppContext()

  const { showGstPricePrimary, showShippingCharges } = checkRoleBasedProperties(user?.data?.roles)

  const SummeryDetailsCardClasses = CN(`${componentId} flex flex-col w-full h-fit`, className)

  const orderTotal = subTotal + gst + (showShippingCharges ? shippingCharges : 0)

  return (
    <div className={SummeryDetailsCardClasses}>
      <div className='flex flex-col gap-[12px] bg-white px-[16px] pb-[16px]'>
        <div className={`${componentId}-subTotal flex flex-row justify-between`}>
          <span className={`${componentId}-subTotal-text text-sm font-normal text-N-700`}>
            Sub-total
          </span>

          <span className={`${componentId}-subTotal-value text-sm font-medium text-N-700`}>
            ${priceFormat(subTotal ?? 0)}
          </span>
        </div>
        {showShippingCharges && (
          <div className={`${componentId}-shipping-charges flex flex-row justify-between text-sm`}>
            <span className={`${componentId}-shipping-charges-text text-sm font-normal text-N-700`}>
              Shipping Charges
            </span>

            <span
              className={`${componentId}-shipping-charges-value text-sm font-medium text-N-700`}>
              ${priceFormat(shippingCharges ?? 0)}
            </span>
          </div>
        )}

        <div className={`${componentId}-gstPrice flex flex-row justify-between`}>
          <span className={`${componentId}-gstPrice-text text-sm font-normal text-N-700`}>GST</span>

          <span className={`${componentId}-gstPrice-value text-sm font-medium text-N-700`}>
            ${priceFormat(gst ?? 0)}
          </span>
        </div>
      </div>

      <div className='flex flex-col bg-B-25 px-[16px] py-[16px]'>
        <div className={`${componentId}-orderTotal flex flex-row justify-between`}>
          {!isInvoiceDetails && showGstPricePrimary && (
            <span className={`${componentId}-orderTotal-text text-sm font-bold text-N-700`}>
              Order Total (incl.GST)
            </span>
          )}
          {!isInvoiceDetails && !showGstPricePrimary && (
            <span className={`${componentId}-orderTotal-text text-sm font-bold text-N-700`}>
              Order Total
            </span>
          )}

          {isInvoiceDetails && (
            <span className={`${componentId}-orderTotal-text text-sm font-bold text-N-700`}>
              Total Due
            </span>
          )}
          <span className={`${componentId}-orderTotal-value text-sm font-bold text-N-700`}>
            ${priceFormat(orderTotal ?? 0)} (NZD)
          </span>
        </div>
      </div>
    </div>
  )
}

export default SummeryDetailsCard
