import React, { FC } from 'react'
import CN from 'classnames'

export interface OrderHistoryAddressCardProps {
  city?: string
  className?: string
  collectPersonDetails?: {
    contactNumber: string
    emailAddress: string
    firstName: string
    lastName: string
  }
  companyName?: string
  componentId: string
  header?: string
  postCode?: string
  streetAddress?: string
  suburb?: string
}

export const OrderHistoryAddressCard: FC<OrderHistoryAddressCardProps> = ({
  city,
  className,
  collectPersonDetails,
  companyName,
  componentId,
  header,
  postCode,
  streetAddress,
  suburb
}: OrderHistoryAddressCardProps) => {
  return (
    <div
      className={CN(
        `${componentId}-address-card flex flex-col w-full border border-N-200 p-5 bg-white items-start`,
        className
      )}>
      {header && <span className='text-B-500 text-sm font-700 pb-1'>{header}</span>}
      <div className='flex flex-col items-start gap-2'>
        {companyName && (
          <span className='text-N-800 text-h3 font-600 uppercase'>{companyName}</span>
        )}
        {streetAddress && <span className='text-N-700 text-base font-400'>{streetAddress}</span>}
        {!collectPersonDetails && (suburb || city) && (
          <span className='text-N-700 text-base font-400'>
            {suburb}
            {suburb && ', '}
            {city}
          </span>
        )}
        {collectPersonDetails && (suburb || city) && (
          <>
            <span className='text-N-700 text-base font-400'>{suburb}</span>
            <span className='text-N-700 text-base font-400'>{city}</span>
          </>
        )}
        {postCode && <span className='text-N-700 text-base font-400'>{postCode}</span>}
      </div>
      {collectPersonDetails && (
        <div className='flex flex-col pt-5'>
          <span className='text-base font-700 text-N-700 pb-1'>Person to Collect Order</span>
          <span className='text-N-700 text-base font-400'>
            {collectPersonDetails?.firstName} {collectPersonDetails?.lastName}
          </span>
          <span className='text-N-700 text-base font-400'>
            {collectPersonDetails?.contactNumber}
          </span>
          <a
            href={`mailto:${collectPersonDetails?.emailAddress}`}
            className='text-base font-400 text-O-500 underline'>
            {collectPersonDetails?.emailAddress}
          </a>
        </div>
      )}
    </div>
  )
}

export default OrderHistoryAddressCard
