import Router, { useRouter } from 'next/router'
import React, { FC, useEffect, useState } from 'react'
import { Alert, Button, ButtonProps, Modal, ModalBody, ModalHeader, Spinner } from '@nzsb/shopnx-ui'
import { AnimatePresence, motion } from 'framer-motion'
import { useConfirmEmail } from 'lib/actions'
import { useResendActivationLink } from 'lib/actions/auth'
import { useModalContext } from 'lib/contexts/modal-context'

import { OnScreenMessage } from 'components/molecules'

export interface IVerifyAccountModalProps {
  isOpen?: boolean
  onClose?: any
  className?: string
}

export const VerifyAccountModal: FC<IVerifyAccountModalProps> = ({
  className,
  ...restProps
}: IVerifyAccountModalProps) => {
  const router = useRouter()

  const { loginModal } = useModalContext()

  const { verifyEmailModal, linkToCreditAccountModal } = useModalContext()
  const {
    data: resendActivationLinkResponse,
    isPending: isLoadingActivationLink,
    resendActivationLinkAsync
  } = useResendActivationLink()

  /* ClientSide: Handle verify account */
  const resendLink = async () => {
    const response = await resendActivationLinkAsync({ userId: router?.query?.userid })
    return response
  }

  useEffect(() => {
    if (router?.query?.verifyAccount === 'open') {
      verifyEmailModal.open()
    }
  }, [router])

  const [isEmailVerifiedStatus, setIsEmailVerifiedStatus] = useState({
    isEmailVerified: false,
    type: '',
    header: '',
    subHeader: '',
    description: ''
  })
  const [showWhatIsCreditAccountAlert, setShowWhatIsCreditAccountAlert] = useState(false)

  const {
    confirmEmail,
    isPending: confirmEmailIsLoading,
    data: confirmEmailData
  } = useConfirmEmail()

  /* ClientSide: Handle verify account */
  const verifyAccount = async (userId: any, emailToken: any) => {
    const response = await confirmEmail({ userId, emailToken })

    if (response?.messageType === 'success') {
      // on success
      setIsEmailVerifiedStatus({
        isEmailVerified: true,
        type: response?.messageType,
        header: 'Success',
        subHeader: response?.messageTitle,
        description: response?.messageDescription
      })
      Router.push('/account')
    } else {
      setIsEmailVerifiedStatus({
        isEmailVerified: false,
        type: response?.messageType,
        header: 'Attention!',
        subHeader: response?.messageTitle,
        description: response?.messageDescription
      })
    }
    return response
  }

  useEffect(() => {
    if (verifyEmailModal.isOpen) {
      verifyAccount(router?.query?.userid, router?.query?.token)
    }
  }, [verifyEmailModal.isOpen])

  const getActionButtonProps = () => {
    if (confirmEmailData?.messageType === 'info') {
      return {
        children: 'SIGN IN',
        onClick: () => {
          router.replace(router.pathname, router.pathname, { shallow: true })
          Router.push('/')
          verifyEmailModal?.close()
          loginModal?.open()
        },
        appearance: 'secondary-orange' as ButtonProps['appearance'],
        hidden: confirmEmailData?.messageType !== 'info',
        componentId: 'verifyAccountSignInBtn'
      }
    } else if (confirmEmailData?.messageType === 'warning') {
      return {
        children: 'RESEND ACTIVATION EMAIL',
        isLoading: isLoadingActivationLink,
        onClick: resendLink,
        appearance: 'secondary-orange' as ButtonProps['appearance'],
        hidden: confirmEmailData?.messageType !== 'warning',
        componentId: 'verifyAccountResendBtn'
      }
    } else return undefined
  }

  return (
    <Modal
      componentId='VerifyAccountModal'
      isOpen={verifyEmailModal?.isOpen}
      onClickOverlay={verifyEmailModal.close}
      onClose={() => {
        verifyEmailModal.close()
        router.replace(router.pathname, router.pathname, { shallow: true })
        Router.push(isEmailVerifiedStatus.isEmailVerified ? '/account' : '/')
      }}
      className='w-[444px] flex-shrink-0'
      {...restProps}>
      <ModalHeader
        componentId='VerifyAccountModal'
        onClose={() => {
          verifyEmailModal.close()
          router.replace(router.pathname, router.pathname, { shallow: true })
          Router.push(isEmailVerifiedStatus.isEmailVerified ? '/account' : '/')
        }}
        heading={null}
        hasBorder={false}
      />
      <ModalBody componentId='VerifyAccountModal' className='flex flex-col !pt-0 overflow-hidden'>
        {(confirmEmailIsLoading || isLoadingActivationLink) && (
          <div className='w-full flex justify-center items-center'>
            <Spinner />
          </div>
        )}

        {isEmailVerifiedStatus.isEmailVerified && !confirmEmailIsLoading && (
          <OnScreenMessage
            header={confirmEmailData?.messageType === 'success' ? 'Success' : 'Attention!'}
            type={confirmEmailData?.messageType}
            subHeader={confirmEmailData?.messageTitle}
            description={confirmEmailData?.messageDescription}
          />
        )}

        {!isEmailVerifiedStatus.isEmailVerified &&
          !confirmEmailIsLoading &&
          !resendActivationLinkResponse && (
            <OnScreenMessage
              className='px-[12px] pb-[12px]'
              header={confirmEmailData?.messageType === 'success' ? 'Success' : 'Attention!'}
              type={confirmEmailData?.messageType}
              subHeader={confirmEmailData?.messageTitle}
              description={confirmEmailData?.messageDescription}
              actionButtonProps={getActionButtonProps()}
            />
          )}

        {resendActivationLinkResponse && !isLoadingActivationLink && (
          <OnScreenMessage
            className='px-[12px] pb-[12px]'
            header={
              resendActivationLinkResponse?.messageType === 'success' ? 'Success' : 'Attention!'
            }
            type={resendActivationLinkResponse?.messageType}
            subHeader={resendActivationLinkResponse?.messageTitle}
            description={resendActivationLinkResponse?.messageDescription}
          />
        )}

        {isEmailVerifiedStatus.isEmailVerified && !confirmEmailIsLoading && (
          <>
            <span className='text-base text-N-700 font-500 mt-6'>
              Already have a credit account?
            </span>

            <Button
              componentId='LinkCreditAccountButton'
              isRingOffset={false}
              className='mt-2 uppercase'
              appearance='primary-orange'
              size='md'
              onClick={() => {
                linkToCreditAccountModal?.set(!linkToCreditAccountModal?.isOpen)
                verifyEmailModal?.close()
                router.replace(router.pathname, router.pathname, { shallow: true })
                Router.push(isEmailVerifiedStatus.isEmailVerified ? '/account' : '/')
              }}>
              Link Credit Account
            </Button>

            <span className='text-base text-N-700 font-500 mt-4'>Sign up for a credit account</span>

            <Button
              componentId='CreateCreditAccountButton'
              isRingOffset={false}
              className='mt-2 uppercase'
              appearance='secondary-orange'
              size='md'
              onClick={() => {
                // Note : need link to relevant page
              }}>
              Create Credit Account
            </Button>

            <Button
              componentId='WhatIsCreditAccountButton'
              appearance='link-gray'
              className='w-fit mt-[10px]'
              onClick={() => {
                setShowWhatIsCreditAccountAlert(!showWhatIsCreditAccountAlert)
              }}>
              What is a credit account?
            </Button>

            <AnimatePresence>
              {showWhatIsCreditAccountAlert && (
                <motion.div
                  className='w-full'
                  initial={{ height: 0 }}
                  animate={{
                    height: 'auto'
                  }}
                  exit={{ height: 0, opacity: -2 }}>
                  <Alert
                    className='mt-4'
                    alertHeader='Apply for a credit account if you would like to pay for goods or services on invoice'
                    status='Info'
                    variant='inline'
                    closeToast={() => {
                      setShowWhatIsCreditAccountAlert(false)
                    }}
                  />
                </motion.div>
              )}
            </AnimatePresence>
          </>
        )}
      </ModalBody>
    </Modal>
  )
}

export default VerifyAccountModal
