import { useSearchParams } from 'next/navigation'
import { useRouter } from 'next/router'
import { FC, useEffect, useRef, useState } from 'react'
import { ComplexDateRangePicker, TextField } from '@nzsb/shopnx-ui'
import CN from 'classnames'
import { changeRouter } from 'lib/utilities'

export interface IDateInputWithPickerProps {
  componentId: string
  filterButtonText: string
  filterDateRange: any
  label: string
  setFilterButtonText: React.Dispatch<React.SetStateAction<string>>
  setFilterDateRange: React.Dispatch<React.SetStateAction<any>>
}

export const DateInputWithPicker: FC<IDateInputWithPickerProps> = ({
  componentId,
  filterButtonText,
  filterDateRange,
  label,
  setFilterButtonText,
  setFilterDateRange
}: IDateInputWithPickerProps) => {
  const params = useSearchParams()
  const router = useRouter()

  const FROM = 'from'
  const TO = 'to'
  const fromParam = params.get('from')
  const toParam = params.get('to')

  const DATE_PICKER_PLACE_HOLDER = ''
  const dateRangeRef = useRef<HTMLDivElement>(null)
  const dateRangeContainerRef = useRef<HTMLDivElement>(null)

  const [openDatePicker, setOpenDatePicker] = useState(false)

  useEffect(() => {
    if (fromParam && toParam && filterDateRange.key === null) {
      const fromParts = fromParam?.split('/')
      const toParts = toParam?.split('/')
      setFilterDateRange({
        startDate: new Date(`${fromParts[2]}-${fromParts[1]}-${fromParts[0]}`),
        endDate: new Date(`${toParts[2]}-${toParts[1]}-${toParts[0]}`),
        key: 'selection'
      })
    }
  }, [fromParam, toParam])

  const onDatePickerCloseClick = () => {
    setFilterButtonText(DATE_PICKER_PLACE_HOLDER)
    const url = new URL(window.location.href)
    url.searchParams.delete(FROM)
    url.searchParams.delete(TO)
    router.push(url.toString(), undefined, { shallow: true })
    setFilterDateRange({
      startDate: null,
      endDate: null,
      key: 'selection'
    })
  }

  useEffect(() => {
    function handleClickOutside(event: MouseEvent | TouchEvent) {
      if (
        dateRangeContainerRef.current &&
        !dateRangeContainerRef.current.contains(event.target as Node)
      ) {
        setOpenDatePicker(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    document.addEventListener('touchend', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      document.addEventListener('touchend', handleClickOutside)
    }
  }, [dateRangeContainerRef])

  useEffect(() => {
    let text = DATE_PICKER_PLACE_HOLDER

    if (filterDateRange.startDate && filterDateRange.endDate) {
      const { startDate, endDate } = filterDateRange
      const startDateFormatted = startDate.toLocaleDateString('en-GB')
      const endDateFormatted = endDate.toLocaleDateString('en-GB')

      text = `${startDateFormatted} - ${endDateFormatted}`
    }
    setFilterButtonText(text)
  }, [filterDateRange])

  return (
    <div className='relative w-full' ref={dateRangeContainerRef}>
      <TextField
        label={label}
        value={filterButtonText}
        onClick={() => setOpenDatePicker(prev => !prev)}
        componentId={`${componentId}-date-range-picker`}
        readOnly
        placeholder={'DD/MM/YYYY - DD/MM/YYYY'}
        wrapperClassName={CN('w-full flex justify-center capitalize')}
        iconAfter={
          filterButtonText === DATE_PICKER_PLACE_HOLDER
            ? 'ri-calendar-line'
            : 'nzsbi-close text-[16px]'
        }
        onClickIconAfter={() =>
          filterButtonText === DATE_PICKER_PLACE_HOLDER
            ? setOpenDatePicker(prev => !prev)
            : onDatePickerCloseClick()
        }
      />
      {openDatePicker && (
        <div
          className='absolute bg-white z-[10] left-0 top-[74px] shadow rounded'
          data-component-id={`${componentId}-date-range-picker-popup`}
          ref={dateRangeRef}>
          <ComplexDateRangePicker
            calendarFocus='forwards'
            data-component-id={`${componentId}-date-range-picker-popup-container`}
            isRangesDisable={true}
            isFooterButtons={false}
            showDateDisplay={false}
            isResponsive={true}
            onChange={(value: any) => {
              setFilterDateRange(value)
              if (value.startDate && value.endDate) {
                const startDateFormatted = value.startDate.toLocaleDateString('en-GB')
                const endDateFormatted = value.endDate.toLocaleDateString('en-GB')

                changeRouter(router, [TO, FROM], [endDateFormatted, startDateFormatted, 'page'])
              }
            }}
            selectedDate={[filterDateRange.startDate, filterDateRange.endDate]}
          />
        </div>
      )}
    </div>
  )
}
