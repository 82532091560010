import React, { FC } from 'react'
import { Button } from '@nzsb/shopnx-ui'
import { Form, Formik } from 'formik'
import { useChangePassword } from 'lib/actions/auth/changePassword'
import { useLogout } from 'lib/actions/auth/logout'
import { useModalContext } from 'lib/contexts/modal-context'
import { ChangePasswordSchema } from 'lib/schemas/changePassword'

import { PasswordInput } from 'components/atoms'
import { OnScreenMessage } from 'components/molecules'

export interface ChangePasswordProps {}

export interface IFormValues {
  confirmedPassword: string
  currentPassword: string
  newPassword: string
}

export const ChangePassword: FC<ChangePasswordProps> = () => {
  const initialFormValues: IFormValues = {
    confirmedPassword: '',
    currentPassword: '',
    newPassword: ''
  }

  const { loginModal, changePasswordModal } = useModalContext()
  const { logout } = useLogout(false)

  const {
    changePasswordAsync,
    data: changePasswordResponse,
    isPending: isLoadingChangePasswordAction
  } = useChangePassword()

  const handleChangePassword = async (values: IFormValues) => {
    const response = await changePasswordAsync({
      confirmedPassword: values.confirmedPassword,
      currentPassword: values.currentPassword,
      newPassword: values.newPassword
    })

    if (response?.messageType === 'success') {
      logout()
    }
  }

  const onSignInClick = () => {
    changePasswordModal?.close()
    loginModal?.open()
  }

  return (
    <>
      {changePasswordResponse?.messageType === 'success' ? (
        <OnScreenMessage
          header='Success'
          type={changePasswordResponse?.messageType}
          description={changePasswordResponse?.messageDescription}
          actionButtonProps={{
            children: 'SIGN IN',
            appearance: 'secondary-orange',
            onClick: onSignInClick,
            size: 'md',
            componentId: 'changePasswordSignInBtn'
          }}
        />
      ) : (
        <div className='flex flex-col gap-[24px] text-N-700'>
          <h3 className='text-display-h2 font-display uppercase'>Change password</h3>
          <div className='flex flex-col gap-[4px] text-sm font-serif'>
            <span className='font-bold'>For a secure password</span>
            <span>
              Please ensure your password contains a minimum of 8 characters consisting of at least
              one alpha character, uppercase character, numeric value and special character
            </span>
          </div>
          <Formik
            validateOnChange
            initialValues={initialFormValues}
            validationSchema={ChangePasswordSchema}
            onSubmit={handleChangePassword}>
            {({
              errors,
              touched,
              handleChange,
              handleBlur,
              values,
              isInitialValid,
              isValid,
              isSubmitting
            }) => {
              const getPasswordInputHint = () => {
                if (changePasswordResponse?.messageType === 'error')
                  return 'Current password does not match to the one you entered. Please try again'
                else if (errors.currentPassword && touched.currentPassword)
                  return errors.currentPassword
                else return ''
              }

              return (
                <Form>
                  <div className='flex flex-col gap-[20px] w-full'>
                    <PasswordInput
                      componentId='currentPassword'
                      hasError={
                        (errors.currentPassword && touched.currentPassword) ||
                        changePasswordResponse?.messageType === 'error'
                      }
                      hint={getPasswordInputHint()}
                      id='currentPassword'
                      inputSize='md'
                      label='Current Password'
                      onBlur={handleBlur('currentPassword')}
                      onChange={handleChange('currentPassword')}
                      required
                      value={values.currentPassword}
                      wrapperClassName='w-full'
                    />
                    <PasswordInput
                      componentId='newPassword'
                      hasError={errors.newPassword && touched.newPassword}
                      hint={errors.newPassword && touched.newPassword ? errors.newPassword : ''}
                      id='newPassword'
                      inputSize='md'
                      isStrengthBarEnable
                      label='New Password'
                      onBlur={handleBlur('newPassword')}
                      onChange={handleChange('newPassword')}
                      required
                      value={values.newPassword}
                      wrapperClassName='w-full'
                    />
                    <PasswordInput
                      componentId='confirmedPassword'
                      hasError={errors.confirmedPassword && touched.confirmedPassword}
                      hint={
                        errors.confirmedPassword && touched.confirmedPassword
                          ? errors.confirmedPassword
                          : ''
                      }
                      id='confirmedPassword'
                      inputSize='md'
                      label='Confirm New Password'
                      onBlur={handleBlur('confirmedPassword')}
                      onChange={handleChange('confirmedPassword')}
                      required
                      value={values.confirmedPassword}
                      wrapperClassName='w-full'
                    />
                    <Button
                      componentId='change-password-submit'
                      type='submit'
                      size='md'
                      appearance='primary-orange'
                      isLoading={isLoadingChangePasswordAction}
                      disabled={(!isValid && !isInitialValid) || isSubmitting}>
                      CHANGE PASSWORD
                    </Button>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </div>
      )}
    </>
  )
}

export default ChangePassword
