export const downloadPdf = (pdfContent: string, fileName: string) => {
  const pdfByteArray = Uint8Array.from(atob(pdfContent), c => c.charCodeAt(0))
  const blob = new Blob([pdfByteArray], { type: 'application/pdf' })

  const url = URL.createObjectURL(blob)

  const a = document.createElement('a')
  a.href = url
  a.download = fileName
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)

  URL.revokeObjectURL(url)
}

export default downloadPdf
